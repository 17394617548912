import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import useMember from '../../hooks/useMember'
import useUser from '../../hooks/useUser'
import MemberList from './list'
import { TreeView } from './treeView'

function Member() {
  const navigate = useNavigate()
  const [mounted, setMounted] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const { state, fetchMember } = useMember()
  const { state: userState } = useUser()

  const tree = useMemo(() => {
    const result = JSON.parse(userState.user.tree.tree)

    if (result.code === userState.user.code) return result
    else {
      const codes = []
      for (var i = 1; i < userState.user.code.length; i += 2) {
        codes.push(userState.user.code.substr(0, i + 1))
      }

      let tempNode = null
      for (var node = 1; node < codes.length; node++) {
        let currentNode = node
        if (tempNode)
          tempNode = tempNode?.children?.find(
            (r) => r.code === codes[currentNode]
          )
        else
          tempNode = result?.children?.find(
            (r) => r.code === codes[currentNode]
          )
      }

      return tempNode
    }
  }, [userState.user])

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchMember()
    }
  }, [mounted, fetchMember])

  const { purchase } = userState.user

  if (!purchase) {
    return (
      <div className="py-5 d-flex justify-content-center">
        <h4 style={{ color: 'red' }}>You have no active plan</h4>
      </div>
    )
  }

  return (
    <Container>
      <div className="d-flex justify-content-between py-5 px-5">
        <div>
          <Button color="success" onClick={() => navigate('/create-member')}>
            Add New
          </Button>
        </div>
      </div>
      <div>
        <Card style={{ borderRadius: '0px' }}>
          <CardBody style={{ borderRadius: '0px' }}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ 'active-tab': activeTab === '1' })}
                  onClick={() => {
                    setActiveTab('1')
                  }}
                >
                  List
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ 'active-tab': activeTab === '2' })}
                  onClick={() => {
                    setActiveTab('2')
                  }}
                >
                  Tree
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <MemberList data={state.members} />
              </TabPane>
              <TabPane tabId="2">
                <TreeView data={tree} />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    </Container>
  )
}

export default Member
