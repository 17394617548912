import React from 'react'
import {
  store,
  getAll,
  changePassword,
  resetPassword,
} from '../service/memberService'

const initialState = { members: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MEMBERS':
      return { ...state, members: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useMember = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchMember = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_MEMBERS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeMember = async (values) => {
    await store(values)
  }

  const changePasswordMember = async (values) => {
    await changePassword(values)
  }

  const resetPasswordMember = async (id) => {
    await resetPassword(id)
  }

  return {
    storeMember,
    fetchMember,
    changePasswordMember,
    resetPasswordMember,
    state: state,
  }
}

export default useMember
