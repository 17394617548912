import React from 'react'
import { useForm } from 'react-hook-form'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
} from 'reactstrap'
import Logo from '../assets/logo.png'
import TextBox from '../components/Inputs/TextBox'
import useUser from './../hooks/useUser'
import { FiArrowRight } from 'react-icons/fi'
import { FaRegEyeSlash } from 'react-icons/fa'
import { RiErrorWarningLine } from 'react-icons/ri'
function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [showPassword, setShowPassowrd] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { login } = useUser()

  const submitData = async (values) => {
    setLoading(true)
    await login(values)
    setLoading(false)
  }
  const styles = {
    color: '#838383',
  }

  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100%',
          background: '#F2F5F8;',
        }}
      >
        <div className="justify-content-center col-5">
          <Card className="bg-white border-success mb-0 shadow">
            <CardBody className="p-5">
              <div>
                <img
                  src={Logo}
                  style={{
                    width: '95px',
                    height: '95px',
                  }}
                  alt="logo"
                />
              </div>
              <div className="mt-3 mb-5">
                <span style={styles}>Welcome to</span>
                <h4>Cpro</h4>
              </div>

              <Form onSubmit={handleSubmit(submitData)}>
                <FormGroup className="mb-3">
                  <Label style={styles}>
                    Email <RiErrorWarningLine />
                  </Label>
                  <TextBox
                    className="form-control"
                    placeholder="mail@website.com"
                    type="text"
                    registerProps={register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                    errors={errors.email}
                  />
                </FormGroup>
                <FormGroup>
                  <Label style={styles}>
                    Password
                    <RiErrorWarningLine />
                  </Label>
                  <TextBox
                    type={showPassword ? 'text' : 'password'}
                    registerProps={register('password', {
                      required: 'Password is required',
                    })}
                    errors={errors.password}
                    prepend
                    icon={
                      <FaRegEyeSlash
                        style={styles}
                        onClick={() => {
                          setShowPassowrd(!showPassword)
                        }}
                      />
                    }
                  />
                </FormGroup>
                <FormGroup check className="d-flex justify-content-between">
                  <div>
                    <Input type="checkbox" />{' '}
                    <Label check style={styles}>
                      Remember me
                    </Label>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button
                    disabled={loading}
                    className="mt-5 col-12 p-3"
                    size="lg"
                    style={{
                      backgroundColor: '#139F49',
                    }}
                    type="submit"
                  >
                    <div className="d-flex justify-content-between">
                      <span style={{ fontSize: '15px' }}>Log in</span>
                      <FiArrowRight />
                    </div>
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Login
