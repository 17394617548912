import React, { useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Form,
  FormGroup,
} from 'reactstrap'
import usePaymentMethod from '../../hooks/usePaymentMethod'
import TextBox from '../../components/Inputs/TextBox'
import useDeposit from '../../hooks/useDeposit'

function Deposti() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const inputFile = useRef(null)
  const [image, setImage] = useState(null)

  const [mounted, setMounted] = useState(false)

  const { state: paymentState, fetchPaymentMethod } = usePaymentMethod()

  const { storeDeposit } = useDeposit()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchPaymentMethod()
    }
  }, [mounted, fetchPaymentMethod])

  const submitData = async (values) => {
    if (image) {
      const formData = new FormData()
      formData.append('amount', values.amount)
      formData.append('note', values.note)
      formData.append('image', image)
      await storeDeposit(formData)
      reset()
      setImage(null)
    } else {
      NotificationManager.warning('Please upload image!')
    }
  }

  return (
    <Container>
      <div className="py-5">
        <h4 className="text-white">Make Deposit Request</h4>
      </div>
      <Form onSubmit={handleSubmit(submitData)} encType="multipart/form-data">
        <Card>
          <CardBody>
            <FormGroup>
              <label className="mb-2">Amount</label>
              <TextBox
                type="number"
                registerProps={register('amount', {
                  required: 'Amount is required',
                })}
                errors={errors.amount}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Note</label>
              <TextBox
                type="text"
                registerProps={register('note', {
                  required: 'Note is required',
                })}
                errors={errors.note}
              />
            </FormGroup>
            <FormGroup>
              <div className="mt-5">
                {image ? (
                  <img
                    src={URL.createObjectURL(image)}
                    style={{ width: 300, height: 300 }}
                    onClick={() => {
                      inputFile.current.click()
                    }}
                    alt=""
                  />
                ) : (
                  <div
                    onClick={() => {
                      inputFile.current.click()
                    }}
                    style={{ width: 300, height: 300, background: '#c3b7b7' }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <h4>Upload+</h4>
                  </div>
                )}
              </div>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(event) => {
                  if (event.target.files[0]) {
                    setImage(event.target.files[0])
                  }
                }}
                accept="image/*"
              />
            </FormGroup>
            <FormGroup className="mt-2">
              <div className="d-flex justify-content-center py-4">
                <label className="mb-2 text-success">Payment Method</label>
              </div>
              {paymentState.paymentMethods.map((payment, index) => (
                <div
                  className="d-flex justify-content-between mb-3"
                  key={index}
                >
                  <div style={{ textAlign: 'center' }}>
                    <h6>QR Code</h6>
                    <img
                      style={{ width: 150, height: 150 }}
                      src={payment.QRCode}
                      alt="QR Code"
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <h6>Email ({payment.email})</h6>
                    <img
                      style={{ width: 150, height: 150 }}
                      src="https://www.logo.wine/a/logo/Binance/Binance-Vertical2-Dark-Background-Logo.wine.svg"
                      alt="Email"
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <h6>Address ({payment.address})</h6>
                    <img
                      style={{ width: 150, height: 150 }}
                      src="https://www.logo.wine/a/logo/Binance/Binance-Vertical2-Dark-Background-Logo.wine.svg"
                      alt="Address"
                    />
                  </div>
                </div>
              ))}
            </FormGroup>
          </CardBody>
          <CardFooter className="py-4 d-flex justify-content-center">
            <Button size="lg" color="success">
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  )
}

export default Deposti
