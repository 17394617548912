import React from 'react'
import Tree, { useTreeState } from 'react-hyper-tree'

export const TreeView = ({ data }) => {
  const { required, handlers } = useTreeState({
    data,
    id: 'memberTree',
  })

  return (
    <div className="py-4 px-3">
      <h6 className="mb-3">Your Tree Structure</h6>
      <Tree {...required} {...handlers} />
    </div>
  )
}
