import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Form,
  FormGroup,
} from 'reactstrap'
import TextBox from '../../components/Inputs/TextBox'
import SelectBox from '../../components/SelectBox'
import useTransfer from '../../hooks/useTransfer'
import useTransferAllowance from '../../hooks/useTransferAllowance'
import useUser from '../../hooks/useUser'

function Transfer() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm()
  const [mounted, setMounted] = useState(false)
  const [amount, setAmount] = useState(null)

  const { state: userState } = useUser()
  const { state, fetchTransferMembers, storeTransfer } = useTransfer()

  const {
    state: transferAllowanceState,
    fetchTransferAllowance,
  } = useTransferAllowance()

  const transferAllowances = transferAllowanceState.transferAllowances.map(
    (el) => {
      return {
        value: el.amount,
        label: el.amount,
      }
    }
  )

  const options = state.transferMembers.map((el) => {
    return {
      value: el.id,
      label: `${el.name}(${el.code})`,
    }
  })

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchTransferAllowance()
      fetchTransferMembers()
    }
  }, [mounted, fetchTransferMembers, fetchTransferAllowance])

  const submitData = async (values) => {
    if (userState?.user?.wallet < values.amount) {
      NotificationManager.warning("You don't have enough amount!")
    } else {
      await storeTransfer(values)
      window.location = '/wallet'
      reset()
      setAmount(null)
    }
  }

  return (
    <Container>
      <div className="py-5">
        <h4 className="text-white">Make Transfer</h4>
      </div>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardBody>
            <FormGroup>
              <label className="mb-2">To Member</label>
              <SelectBox
                control={control}
                className="form-control"
                name="toMemberId"
                rules={{ required: true }}
                label="Amount"
                options={options}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Amount</label>
              <SelectBox
                control={control}
                className="form-control"
                name="amount"
                rules={{ required: true }}
                label="Amount"
                options={transferAllowances}
                value={amount}
                onValueChange={(value) => {
                  setAmount(value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Note</label>
              <TextBox
                type="text"
                registerProps={register('note', {
                  required: 'Note is required',
                })}
                errors={errors.note}
              />
            </FormGroup>
          </CardBody>
          <CardFooter className="py-4 d-flex justify-content-center">
            <Button size="lg" color="success">
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  )
}

export default Transfer
