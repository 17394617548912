import { Row, Col, Card, CardBody, Button, CardFooter } from 'reactstrap'
import { FaAudioDescription, FaMendeley } from 'react-icons/fa'
import { useCallback, useEffect, useState } from 'react'
import { getAds } from './../../service/adsService'
import { useNavigate } from 'react-router-dom'
import useUser from './../../hooks/useUser'
import WalletIcon from './../../assets/wallet.png'
import './index.scss'

export default function Dashboard() {
  const { state: userState, getHomePageData } = useUser()
  const navigate = useNavigate()
  const [adsLoading, setAdsLoading] = useState(false)

  const viewAds = useCallback(async () => {
    window.location = '/test_ads'
  }, [navigate])

  useEffect(() => {
    async function fetchData() {
      await getHomePageData()
    }
    fetchData()
  }, [])

  const { purchase } = userState.user

  if (userState.loading)
    return (
      <div className="p-4">
        <h1 className="text-white">Loading...</h1>
      </div>
    )

  return (
    <div className="p-4">
      {!purchase && (
        <div className="d-flex justify-content-center">
          <h4 style={{ color: 'red' }}>You have no active plan</h4>
        </div>
      )}
      <div className="home-content">
        <Row>
          <Col md={6}>
            <Card>
              <CardBody className="d-flex flex-column align-items-center justify-content-center bg-white">
                <FaAudioDescription color="hsl(11deg 100% 62%)" />
                {purchase && (
                  <h4>
                    {userState.homeData.lastView
                      ? userState.homeData.lastView.finish
                        ? userState.homeData.lastView.counter
                        : userState.homeData.lastView.counter ===
                          userState.homeData.lastView.totalClick
                        ? userState.homeData.lastView.counter
                        : userState.homeData.lastView.counter - 1
                      : 0}
                    /{purchase.plan.totalClick}
                  </h4>
                )}
              </CardBody>
              <CardFooter className="d-flex justify-content-end py-3">
                <Button onClick={viewAds}>
                  {adsLoading ? 'Loading...' : 'View Ads'}
                </Button>
              </CardFooter>
            </Card>
          </Col>
          {purchase && (
            <>
              <Col md={6}>
                <Card>
                  <CardBody className="d-flex flex-column align-items-center justify-content-center bg-white">
                    <FaMendeley color="green" />
                    <h4>{userState.homeData.members} Members</h4>
                  </CardBody>
                  <CardFooter className="d-flex justify-content-end py-3">
                    <Button
                      onClick={() => {
                        navigate('/member')
                      }}
                    >
                      View Detail
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              {/* <Col md={6}>
                <div className="finance-info">
                  <h4 className="text-white">Today Income</h4>
                  <img src={IncomeLogo} alt="Logo of income" />
                  <div className="d-flex justify-content-between">
                    <h3 className="text-white">200,000 USDT</h3>
                    <Button color="success">View Detail</Button>
                  </div>
                </div>
              </Col> */}
              <Col md={6}>
                <div className="finance-info">
                  <h4 className="text-white">Your Wallet</h4>
                  <img src={WalletIcon} alt="Logo of income" />
                  <div className="d-flex justify-content-between">
                    <h3 className="text-white">{userState.user.wallet} USDT</h3>
                    <Button
                      color="success"
                      onClick={() => {
                        navigate('/wallet')
                      }}
                    >
                      View Detail
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  )
}
