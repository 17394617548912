import React, { useEffect, useState } from 'react'
import usePlan from '../../hooks/usePlan'
import useUser from '../../hooks/useUser'
import './index.scss'
import PlanDetail from './PlanDetail'
function Plans() {
  const [mounted, setMounted] = useState(false)
  const { fetchPlan, state: planState } = usePlan()
  const { state: userState } = useUser()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchPlan()
    }
  }, [mounted, fetchPlan])

  const { purchase } = userState.user

  return (
    <>
      <div className="py-5 d-flex justify-content-center">
        {!purchase ? (
          <h4 style={{ color: 'red' }}>You have no active plan</h4>
        ) : (
          <h3 className="text-white">
            Your current plans : {purchase?.plan?.name}
          </h3>
        )}
      </div>
      <div className="d-flex flex-wrap plan-card-container justify-content-center">
        {planState.plans.map((plan, index) => (
          <PlanDetail key={`plan-detail-${index + 1}`} data={plan} />
        ))}
      </div>
    </>
  )
}
export default Plans
