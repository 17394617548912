import React from 'react'
import * as AuthService from './../service/authService'

import { getCache } from './../utils/cache'
//dummy

const initialState = {
  user: JSON.parse(getCache('user')) || {},
  loading: false,
  token: null,
  homeData: {},
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_TOKEN': {
      return { ...state, token: action.payload.data }
    }
    case 'SET_HOME_DATA': {
      return { ...state, homeData: action.payload }
    }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useUser = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const login = async (values) => {
    if (await AuthService.login(values)) {
      await fetchUserProfile()
      window.location = '/'
    }
  }

  const checkLogin = () => {
    const token = getCache('access_token')
    dispatch({ type: 'SET_TOKEN', payload: { data: token } })
    if (!token) {
      window.location = '/auth/login'
      return
    }
  }

  const getHomePageData = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const response = await AuthService.getHomePageData()
    dispatch({ type: 'SET_HOME_DATA', payload: response })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchUserProfile = async () => {
    await AuthService.getUserProfile()
  }

  const logout = () => {
    AuthService.logout()
    window.location = '/auth/login'
  }

  return {
    login,
    logout,
    checkLogin,
    fetchUserProfile,
    getHomePageData,
    state: state,
  }
}

export default useUser
