import React, { useState } from 'react'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from 'reactstrap'
import './navbar.scss'
import { useLocation } from 'react-router-dom'
import useUser from '../../hooks/useUser'
import Logo from './../../assets/logo.png'
import { VscThreeBars } from 'react-icons/vsc'

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const routes = [
    {
      name: 'Home',
      link: '/',
    },

    {
      name: 'Plans',
      link: '/plans',
    },
    {
      name: 'Wallet',
      link: '/wallet',
    },
    {
      name: 'Member',
      link: '/member',
    },
    {
      name: 'Change Password',
      link: '/change-password',
    },
  ]
  const location = useLocation()
  const { state: userState, logout } = useUser()
  const { purchase } = userState.user

  return (
    <div className="custom-nav">
      <Navbar className="px-5 nav">
        <div className="d-flex">
          <div className="d-flex justify-content-center align-items-center me-3">
            <VscThreeBars
              onClick={toggle}
              className="fs-5"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <NavbarBrand
            href="/"
            className="d-flex justify-content-center align-items-center"
          >
            <img
              alt="logo"
              src={Logo}
              style={{
                height: 78,
                width: 78,
              }}
            />
            <div className="logo">
              <h5>Cpro</h5>
              <span>Northen America</span>
            </div>
          </NavbarBrand>
        </div>
        <div className="d-flex align-items-center ">
          <div className="d-flex">
            <div className="admin-profile align-items-end">
              <h5>
                {userState?.user?.name}{' '}
                {purchase && <span>({purchase.plan.name})</span>}
              </h5>
              <span>{userState?.user?.wallet} USDT</span>
            </div>
            <div className="admin-logo">
              <span>{userState?.user?.code}</span>
            </div>
          </div>
        </div>
      </Navbar>
      <div>
        <Offcanvas
          scrollable
          toggle={toggle}
          isOpen={isOpen}
          style={{ minWidth: '250px', maxWidth: '330px' }}
        >
          <OffcanvasHeader toggle={toggle}></OffcanvasHeader>
          <OffcanvasBody>
            <Navbar className="px-2 py-3">
              <Nav className="flex-column" navbar>
                {routes.map((route, index) => (
                  <NavItem
                    className={`px-2 mb-3 ${
                      route.link === location.pathname ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <NavLink style={{ cursor: 'pointer' }} href={route.link}>
                      {route.name}
                    </NavLink>
                  </NavItem>
                ))}
                <NavLink
                  onClick={() => {
                    logout()
                  }}
                  className="px-2 mb-3"
                >
                  Logout
                </NavLink>
              </Nav>
            </Navbar>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </div>
  )
}
export default NavBar
