import React, { useState } from 'react'
import { Button, Table } from 'reactstrap'
import dayjs from 'dayjs'
import useMember from '../../hooks/useMember'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
function MemberList({ data }) {
  const [editData, setEditData] = useState(null)
  const [showReset, setShowReset] = useState(false)

  const { fetchMember, resetPasswordMember } = useMember()

  return (
    <>
      <Table className="mt-3" striped>
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Code</th>
            <th>Email</th>
            <th>Created At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((member, index) => (
            <tr key={member.id}>
              <td>{index + 1}</td>
              <td>{member.name}</td>
              <td>{member.code}</td>
              <td>{member.email}</td>
              <td>{dayjs(member.createdAt).format('DD/MM/YYYY')}</td>
              <td>
                <Button
                  size="sm"
                  color="info"
                  onClick={() => {
                    setShowReset(true)
                    setEditData(member)
                  }}
                >
                  Reset
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <p className="pt-3">Total : {data.length}</p>
        </tfoot>
      </Table>
      <ConfirmDialog
        modalOpen={showReset}
        title={`Reset Password`}
        body={`Are you sure to reset password of ${editData?.name}?`}
        onToggle={() => setShowReset(false)}
        onConfirm={() => {
          setShowReset(false)
          resetPasswordMember(editData?.id)
          setTimeout(() => {
            fetchMember()
          }, 1000)
        }}
      />
    </>
  )
}

export default MemberList
