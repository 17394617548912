import React, { useEffect } from 'react'
import { Adsense } from '@ctrl/react-adsense'
function TestAds() {
  useEffect(() => {
    // ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <div>
      <h1>TestAds</h1>
      <Adsense
        client="ca-pub-1826851503983015"
        slot="1272183710"
        adTest="on"
        style={{ display: 'inline-block', border: '1px solid green' }}
      />
    </div>
  )
}

export default TestAds
