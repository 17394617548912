import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { Card, CardBody, CardFooter, Button } from 'reactstrap'
import useUser from '../../hooks/useUser'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import usePurchase from '../../hooks/usePurchase'
import useWithdraw from '../../hooks/useWithdraw'

function PlanDetail({ data }) {
  const [showConfirm, setShowConfirm] = useState(false)
  const [mounted, setMounted] = useState(false)

  const { state: userState, fetchUserProfile } = useUser()
  const { storePurchase } = usePurchase()
  const { state: withdrawState, fetchWithdraw } = useWithdraw()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchWithdraw()
    }
  }, [mounted, fetchWithdraw])

  const handlePurchase = () => {
    let pendingTotal = 0

    withdrawState.withdraws.forEach((withdraw) => {
      if (withdraw.status === 0) {
        pendingTotal += withdraw.amount
      }
    })

    let amount = data.price - data.price * (data.bonus / 100)
    amount += pendingTotal

    if (userState?.user?.wallet < amount) {
      NotificationManager.warning("You don't have enough wallet!")
    } else {
      setShowConfirm(true)
    }
  }

  const purchaseProcess = async () => {
    const amount = data.price - data.price * (data.bonus / 100)

    await storePurchase({
      amount,
      planId: data.id,
    })
    await fetchUserProfile()
    window.location.reload()
  }

  return (
    <>
      <Card>
        <CardBody className="title-box">
          <h5>{data.name}</h5>
        </CardBody>
        <CardBody style={{ height: '500px', overflowY: 'auto' }}>
          {data.bonus > 0 && (
            <div className="d-flex">
              <h6 style={{ width: '200px' }}>Bonus : </h6>
              <h6 className="text-danger">{data.bonus}%</h6>
            </div>
          )}
          <div className="d-flex">
            <h6 style={{ width: '200px' }}>Acitivty Fee : </h6>
            <h6>{data.activityIncome}%</h6>
          </div>
          <div className="d-flex">
            <h6 style={{ width: '200px' }}>No Acitivty Fee : </h6>
            <h6>{data.noActivityIncome}%</h6>
          </div>
          <div className="d-flex">
            <h6 style={{ width: '200px' }}>No of Levels</h6>
            <h6>{data.maxLevel}</h6>
          </div>
          <div className="d-flex">
            <h6 style={{ width: '200px' }}>Duration</h6>
            <h6>
              {data.duration === 0 ? 'Permanent' : `${data.duration} days`}
            </h6>
          </div>
          <div className="d-flex">
            <h6 style={{ width: '200px' }}>Total Clicks</h6>
            <h6>{data.totalClick}</h6>
          </div>
          <div className="d-flex">
            <h6 style={{ width: '200px' }}>Min Refer</h6>
            <h6>{data.referCount}</h6>
          </div>
          {data.plan_settings.map((setting, index) => (
            <div className="py-3" key={index}>
              <div style={{ paddingLeft: '20px' }}>
                <h5>Level {setting.level}</h5>
              </div>

              <div className="d-flex">
                <h6 style={{ width: '200px' }}>Acitivty Fee : </h6>
                <h6>{setting.activityFee}%</h6>
              </div>
              <div className="d-flex">
                <h6 style={{ width: '200px' }}>No Acitivty Fee : </h6>
                <h6>{setting.noActivityFee}%</h6>
              </div>
            </div>
          ))}
        </CardBody>
        <CardFooter className="py-3 d-flex justify-content-between">
          <h4>{data.price} USDT</h4>
          <Button onClick={handlePurchase}>Purchase</Button>
        </CardFooter>
      </Card>
      <ConfirmDialog
        modalOpen={showConfirm}
        title={`Confirm Purchase`}
        body={`Are you sure to buy (${data.name})?`}
        onToggle={() => setShowConfirm(false)}
        onConfirm={async () => {
          setShowConfirm(false)
          const { purchase } = userState.user

          if (purchase) {
            if (data.price > purchase.plan?.price) {
              purchaseProcess()
            } else {
              NotificationManager.warning('Please buy upper plan!')
            }
          } else {
            purchaseProcess()
          }
        }}
      />
    </>
  )
}

export default PlanDetail
