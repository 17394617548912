import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Form,
  FormGroup,
} from 'reactstrap'
import usePaymentMethod from '../../hooks/usePaymentMethod'
import TextBox from '../../components/Inputs/TextBox'
import SelectBox from '../../components/SelectBox'
import useWithdrawAllowance from '../../hooks/useWithdrawAllowance'
import useWithdraw from '../../hooks/useWithdraw'
import useUser from '../../hooks/useUser'
import { NotificationManager } from 'react-notifications'

function Withdraw() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm()

  const [mounted, setMounted] = useState(false)
  const [amount, setAmount] = useState(null)
  const [paymentType, setPaymentType] = useState(null)

  const { state: userState } = useUser()

  const { state: paymentState, fetchPaymentMethod } = usePaymentMethod()
  const {
    state: withdrawAllowanceState,
    fetchWithdrawAllowance,
  } = useWithdrawAllowance()

  const options = withdrawAllowanceState.withdrawAllowances.map((el) => {
    return {
      value: el.amount,
      label: el.amount,
    }
  })

  const { storeWithdraw } = useWithdraw()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchPaymentMethod()
      fetchWithdrawAllowance()
    }
  }, [mounted, fetchPaymentMethod, fetchWithdrawAllowance])

  const submitData = async (values) => {
    if (userState?.user?.wallet < values.total) {
      NotificationManager.warning("You don't have enough total!")
    } else {
      await storeWithdraw(values)
      reset()
      setAmount(null)
      setPaymentType(null)
    }
  }

  return (
    <Container>
      <div className="py-5">
        <h4 className="text-white">Make Withdraw Request</h4>
      </div>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardBody>
            <FormGroup>
              <label className="mb-2">Amount</label>
              <SelectBox
                control={control}
                className="form-control"
                name="amount"
                rules={{ required: true }}
                label="Amount"
                options={options}
                value={amount}
                onValueChange={(value) => {
                  setAmount(value)
                  if (paymentType) {
                    if (paymentType === 'Address') {
                      let withdrawFee = value * 0.05
                      setValue('withdrawFee', withdrawFee)
                      let total = value + withdrawFee
                      setValue('total', total)
                    }
                    if (paymentType === 'Binance') {
                      let withdrawFee = value * 0.01
                      setValue('withdrawFee', withdrawFee)
                      let total = value + withdrawFee
                      setValue('total', total)
                    }
                  } else {
                    setValue('withdrawFee', 0)
                    setValue('total', 0)
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Payment Type</label>
              <SelectBox
                control={control}
                className="form-control"
                name="paymentType"
                rules={{ required: true }}
                label="Payment Type"
                options={[
                  { value: 'Address', label: 'Address' },
                  { value: 'Binance', label: 'Binance' },
                ]}
                value={paymentType}
                onValueChange={(value) => {
                  setPaymentType(value)
                  if (amount) {
                    if (value === 'Address') {
                      let withdrawFee = amount * 0.05
                      setValue('withdrawFee', withdrawFee)
                      let total = amount + withdrawFee
                      setValue('total', total)
                    }
                    if (value === 'Binance') {
                      let withdrawFee = amount * 0.01
                      setValue('withdrawFee', withdrawFee)
                      let total = amount + withdrawFee
                      setValue('total', total)
                    }
                  } else {
                    setValue('withdrawFee', 0)
                    setValue('total', 0)
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Note</label>
              <TextBox
                type="text"
                registerProps={register('note', {
                  required: 'Note is required',
                })}
                errors={errors.note}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Withdraw Fee</label>
              <TextBox
                type="number"
                registerProps={register('withdrawFee', {
                  required: 'Withdraw Fee is required',
                })}
                errors={errors.withdrawFee}
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Total</label>
              <TextBox
                type="number"
                registerProps={register('total', {
                  required: 'Total is required',
                })}
                errors={errors.total}
                disabled={true}
              />
            </FormGroup>
            <FormGroup className="mt-2">
              <div className="d-flex justify-content-center py-4">
                <label className="mb-2 text-success">Payment Method</label>
              </div>
              {paymentState.paymentMethods.map((payment, index) => (
                <div
                  className="d-flex justify-content-between mb-3"
                  key={index}
                >
                  <div style={{ textAlign: 'center' }}>
                    <h6>QR Code</h6>
                    <img
                      style={{ width: 150, height: 150 }}
                      src={payment.QRCode}
                      alt="QR Code"
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <h6>Email ({payment.email})</h6>
                    <img
                      style={{ width: 150, height: 150 }}
                      src="https://www.logo.wine/a/logo/Binance/Binance-Vertical2-Dark-Background-Logo.wine.svg"
                      alt="Email"
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <h6>Address ({payment.address})</h6>
                    <img
                      style={{ width: 150, height: 150 }}
                      src="https://www.logo.wine/a/logo/Binance/Binance-Vertical2-Dark-Background-Logo.wine.svg"
                      alt="Address"
                    />
                  </div>
                </div>
              ))}
            </FormGroup>
          </CardBody>
          <CardFooter className="py-4 d-flex justify-content-center">
            <Button size="lg" color="success">
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  )
}

export default Withdraw
