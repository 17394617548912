import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll() {
  try {
    let response = await getData('withdraw')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function each(id) {
  try {
    let response = await getData(`withdraw/${id}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function store(data) {
  try {
    let response = await sendData('withdraw/store', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
