import React from 'react'
import { getAll } from './../service/withdrawAllowanceService'

const initialState = { withdrawAllowances: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_WITHDRAW_ALLOWANCE':
      return { ...state, withdrawAllowances: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useWithdrawAllowance = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchWithdrawAllowance = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_WITHDRAW_ALLOWANCE', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return {
    fetchWithdrawAllowance,
    state: state,
  }
}

export default useWithdrawAllowance
