import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Form,
  FormGroup,
} from 'reactstrap'
import TextBox from '../../components/Inputs/TextBox'
import { FaRegEyeSlash } from 'react-icons/fa'
import useMember from '../../hooks/useMember'

const styles = {
  color: '#838383',
}

function MemberCreate() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [showPassword, setShowPassowrd] = useState(false)

  const { storeMember } = useMember()

  const submitData = async (values) => {
    await storeMember(values)
    reset()
  }

  return (
    <Container>
      <div className="py-5">
        <h4 className="text-white">Create Clicker</h4>
      </div>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardBody>
            <FormGroup>
              <label className="mb-2">Name</label>
              <TextBox
                type="text"
                registerProps={register('name', {
                  required: 'Name is required',
                })}
                errors={errors.name}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Email</label>
              <TextBox
                type="email"
                registerProps={register('email', {
                  required: 'Email is required',
                })}
                errors={errors.email}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Password</label>
              <TextBox
                type={showPassword ? 'text' : 'password'}
                registerProps={register('password', {
                  required: 'Password is required',
                })}
                errors={errors.password}
                prepend
                icon={
                  <FaRegEyeSlash
                    style={styles}
                    onClick={() => {
                      setShowPassowrd(!showPassword)
                    }}
                  />
                }
              />
            </FormGroup>
          </CardBody>
          <CardFooter className="py-4 d-flex justify-content-center">
            <Button size="lg" color="success">
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  )
}

export default MemberCreate
