import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import {
  Button,
  Card,
  CardBody,
  Container,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
} from 'reactstrap'
import WalletIcon from './../../assets/wallet.png'
import './index.scss'
import useDeposit from '../../hooks/useDeposit'

import dayjs from 'dayjs'
import useUser from '../../hooks/useUser'
import useWithdraw from '../../hooks/useWithdraw'
import useTransfer from '../../hooks/useTransfer'

function Wallet() {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('1')
  const [mounted, setMounted] = useState(false)
  const [modal, setModal] = useState(false)
  const [image, setImage] = useState('')

  const { state, fetchDeposit } = useDeposit()
  const { state: withdrawState, fetchWithdraw } = useWithdraw()
  const { state: transferState, fetchTransfer } = useTransfer()

  const { state: userState } = useUser()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchDeposit()
      fetchWithdraw()
      fetchTransfer()
    }
  }, [mounted, fetchDeposit, fetchWithdraw, fetchTransfer])

  const handleShow = (url) => {
    setModal(true)
    setImage(url)
  }

  const handleShowWithdraw = () => {
    const today = dayjs(Date.now()).format('DD/MM/YYYY')
    const result = withdrawState.withdraws.find(
      (withdraw) => dayjs(withdraw.createdAt).format('DD/MM/YYYY') === today
    )

    if (!result) {
      return true
    }

    return false
  }

  return (
    <Container>
      <div className="d-flex justify-content-between py-5 px-5">
        <div>
          <Button color="success" onClick={() => navigate('/deposit')}>
            Deposit
          </Button>
          {handleShowWithdraw() && (
            <Button
              color="success"
              onClick={() => navigate('/withdraw')}
              style={{ marginLeft: '20px' }}
            >
              Withdraw
            </Button>
          )}
          <Button
            color="success"
            onClick={() => navigate('/transfer')}
            style={{ marginLeft: '20px' }}
          >
            Transfer
          </Button>
        </div>
        <div>
          <div className="finance-info">
            <img src={WalletIcon} alt="Logo of income" />
            <div className="d-flex justify-content-between">
              <h3 className="text-white">
                {userState?.user?.wallet.toLocaleString()} USDT
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Card style={{ borderRadius: '0px' }}>
          <CardBody style={{ borderRadius: '0px' }}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ 'active-tab': activeTab === '1' })}
                  onClick={() => {
                    setActiveTab('1')
                  }}
                >
                  Deposit
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ 'active-tab': activeTab === '2' })}
                  onClick={() => {
                    setActiveTab('2')
                  }}
                >
                  Withdraw
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ 'active-tab': activeTab === '3' })}
                  onClick={() => {
                    setActiveTab('3')
                  }}
                >
                  Transfer
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Table className="mt-3" striped>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Transcation ID</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.deposits.map((deposit, index) => (
                      <tr key={deposit.id}>
                        <td>{index + 1}</td>
                        <td>{deposit.id}</td>

                        <td>{deposit.amount} USDT</td>
                        <td>{dayjs(deposit.createdAt).format('DD/MM/YYYY')}</td>
                        <td>
                          <span
                            className={
                              deposit.status ? 'text-success' : 'text-primary'
                            }
                          >
                            {deposit.status ? 'Success' : 'Pending'}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TabPane>
              <TabPane tabId="2">
                <Table className="mt-3" striped>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Transcation ID</th>
                      <th>Amount</th>
                      <th>Withdraw Fee</th>
                      <th>Total</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawState.withdraws.map((withdraw, index) => (
                      <tr key={withdraw.id}>
                        <td>{index + 1}</td>
                        <td>{withdraw.id}</td>

                        <td>{withdraw.amount} USDT</td>
                        <td>{withdraw.withdrawFee} USDT</td>
                        <td>{withdraw.total} USDT</td>
                        <td>
                          {dayjs(withdraw.createdAt).format('DD/MM/YYYY')}
                        </td>
                        <td>
                          <span
                            className={
                              withdraw.status === 1
                                ? 'text-success'
                                : withdraw.status === 2
                                ? 'text-danger'
                                : 'text-primary'
                            }
                            onClick={
                              withdraw.status === 1
                                ? () => handleShow(withdraw.image)
                                : null
                            }
                          >
                            {withdraw.status === 1
                              ? 'Success'
                              : withdraw.status === 2
                              ? 'Cancel'
                              : 'Pending'}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TabPane>
              <TabPane tabId="3">
                <Table className="mt-3" striped>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>From Member</th>
                      <th>To Member</th>
                      <th>Amount</th>
                      <th>Note</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transferState.transfers.map((transfer, index) => (
                      <tr key={transfer.id}>
                        <td>{index + 1}</td>
                        <td>{transfer.fromMember?.name}</td>
                        <td>{transfer.toMember?.name}</td>
                        <td>{transfer.amount}</td>
                        <td>{transfer.note}</td>
                        <td>
                          {dayjs(transfer.createdAt).format('DD/MM/YYYY')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => setModal(!modal)}
        style={{ width: '500px', height: '500px' }}
      >
        <img src={image} alt="Receipt" />
      </Modal>
    </Container>
  )
}

export default Wallet
